<template>
    <div class="culture">
     <Top :img="'/gsmm/api/v1/'+columnList.img_info_list[0].url" :ch="ch" />
     		<Title :ch="ch" :en="en"  />
		<div class="content" >
			{{columnList.description}}
		</div>
       <div class="center">
          <Menu v-if="$route.path!=='/culture/culture_detail'" :menuList="columnList.son_tree_column" class="menu" :padding="30" :width="83" />
          <router-view/>
     </div>

    </div>
</template>

<script>
import Top from '../components/top.vue'
import Menu from '../components/menu.vue'
import Title from '../components/title.vue'
import { mapActions, mapState } from 'vuex'
export default {
    data() {
        return {
            ch:'古树文化',
            en:'TREE CULTURE',
        };
    },
  computed:{
     ...mapState(['columnList'])
	},
    components:{
        Top,
        Menu,
        Title
    },
    created() {

    },
    mounted() {
		this.handleColumn(13)
    },
    methods: {
		...mapActions(['handleColumn'])
    }
};
</script>

<style scoped lang='less'>
.culture{
    width:100%;
	background-image: url('../../assets/bg.png');
	padding-bottom: 50px;
}
.content{
	padding: 20px 30%;
}
.center{
    padding:0 20%;
}
</style>
